import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect } from "react";
import { Suspense, lazy } from "react";
import {Loading} from "./components/Loading/Loading";
import axios from "axios";
import {useTranslation} from "react-i18next";

// Ленивое подключение страниц
const MainPage = lazy(() => import("./pages/MainPage/MainPage"));
const InfoPage = lazy(() => import("./pages/InfoPage/InfoPage"));
const ApplyAmuletPage = lazy(() => import("./pages/ApplyAmuletPage/ApplyAmuletPage"));
const CarouselPage = lazy(() => import("./pages/CarouselPage/CarouselPage"));
const PostersPage = lazy(() => import("./pages/PostersPage/PostersPage"));
const SuperpowersPage = lazy(() => import("./pages/SuperpowersPage/SuperpowersPage"));
const AmuletsPage = lazy(() => import("./pages/AmuletsPage/AmuletsPage"));
const GetPage = lazy(() => import("./pages/GetPage/GetPage"));
const GetMessagePage = lazy(() => import("./pages/GetMessagePage/GetMessagePage"));
const BuyPresentPage = lazy(() => import("./pages/BuyPresentPage/BuyPresentPage"));
const GetPresentPage = lazy(() => import("./pages/GetPresentPage/GetPresentPage"));
const ScanRolePage = lazy(() => import("./pages/ScanRolePage/ScanRolePage"));
const ChooseTablePage = lazy(() => import("./pages/ChooseTablePage/ChooseTablePage"));
const ChoosePlacePage = lazy(() => import("./pages/ChoosePlacePage/ChoosePlacePage"));
const PresentPage = lazy(() => import("./pages/PresentPage/PresentPage"));
const ScanCardsPage = lazy(() => import("./pages/ScanRolePage/ScanCardsPage"));

function App() {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        const tg = window.Telegram?.WebApp;
        tg.ready();
        tg.expand();
        const fetchData = async () => {
            try {
                const userId = tg?.initDataUnsafe?.user?.id || Number(process.env.REACT_APP_TEST_USER_ID);
                const resUserAll = await axios.get('https://api.mafia.top/api/userAll');

                const user = resUserAll.data.find((u) => u.chat_id === userId);
                await i18n.changeLanguage(user.language);
                console.log(user.language);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);


    return (
        <Router>
            {/* Suspense позволяет отображать fallback (например, загрузчик) во время загрузки ленивых компонентов */}
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/info" element={<InfoPage />} />
                    <Route path="/applyAmulet" element={<ApplyAmuletPage />} />
                    <Route path="/carousel" element={<CarouselPage />} />
                    <Route path="/posters" element={<PostersPage />} />
                    <Route path="/superpowers" element={<SuperpowersPage />} />
                    <Route path="/amulets" element={<AmuletsPage />} />
                    <Route path="/get" element={<GetPage />} />
                    <Route path="/getMessage" element={<GetMessagePage />} />
                    <Route path="/buyPresent" element={<BuyPresentPage />} />
                    <Route path="/getPresent" element={<GetPresentPage />} />
                    <Route path="/scanRole" element={<ScanRolePage />} />
                    <Route path="/chooseTable" element={<ChooseTablePage />} />
                    <Route path="/choosePlace" element={<ChoosePlacePage />} />
                    <Route path="/myPresents" element={<PresentPage />} />
                    <Route path="/scanCards" element={<ScanCardsPage />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default App;
